
.layout {
  min-height: 100vh;
  /* overflow: hidden; */
  @media (max-width: 800px) {
    /* width:  calc(100vw - 30px); */
    margin-left:0px;
}
}




