
@font-face {
  font-family: 'Ambition & Ink';
  src: local('Ambition & Ink'), local('Ambition&Ink'),
      url('fonts/ambitionInk/AmbitionInk.woff2') format('woff2'),
      url('fonts/ambitionInk/AmbitionInk.woff') format('woff'),
      url('fonts/ambitionInk/AmbitionInk.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Palatino LT';
  src: local('Palatino LT Italic'), local('Palatino-LT-Italic'),
      url('fonts/palatinoItalic/PalatinoLTStd-Italic.woff2') format('woff2'),
      url('fonts/palatinoItalic/PalatinoLTStd-Italic.woff') format('woff'),
      url('fonts/palatinoItalic/PalatinoLTStd-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'NeonoirW01';
  src: url('fonts/neonoir/NeonoirW01-Bold.woff2') format('woff2'),
      url('fonts/neonoir/NeonoirW01-Bold.woff') format('woff'),
      url('fonts/neonoir/Neonoir W01 Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'URWGeometricArabic';
  src: url('fonts/geometric arabic/URWGeometricArabic-Medium.woff2') format('woff2'),
      url('fonts/geometric arabic/URWGeometricArabic-Medium.woff') format('woff'),
      url('fonts/geometric arabic/URWGeometricArabic-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'URWGeometricArabic-Reg';
  src: url('fonts/geometric arabic/URWGeometricArabic-Regular.woff2') format('woff2'),
      url('fonts/geometric arabic/URWGeometricArabic-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'URWGeometricArabic-light';
  src: url('fonts/geometric arabic/URWGeometricArabic-Light.woff2') format('woff2'),
      url('fonts/geometric arabic/URWGeometricArabic-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'URWGeometricArabic-ExtraLight';
  src: url('fonts/geometric arabic/URWGeometricArabic-ExtraLight.woff2') format('woff2'),
      url('fonts/geometric arabic/URWGeometricArabic-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LHF Old Tom Poster Letter';
  src: url('fonts/LHFOldTomPosterLetter/LHFOldTomPosterLetter.woff2') format('woff2'),
      url('fonts/LHFOldTomPosterLetter/LHFOldTomPosterLetter.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('fonts/Futura std/FuturaStd-CondensedBold.woff2') format('woff2'),
      url('fonts/Futura std/FuturaStd-CondensedBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('fonts/Futura std/FuturaStd-Condensed.woff2') format('woff2'),
      url('fonts/Futura std/FuturaStd-Condensed.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('fonts/Futura std/FuturaStd-CondensedBold.woff2') format('woff2'),
      url('fonts/Futura std/FuturaStd-CondensedBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('fonts/Futura std/FuturaStd-CondensedLight.woff2') format('woff2'),
      url('fonts/Futura std/FuturaStd-CondensedLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std Book';
  src: url('fonts/Futura book/FuturaStd-Book.woff2') format('woff2'),
      url('fonts/Futura book/FuturaStd-Book.woff') format('woff'),
      url('fonts/Futura book/FuturaStd-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Arabic';
  src: url('fonts/DIN/DINNextLTArabic-Regular.woff2') format('woff2'),
      url('fonts/DIN/DINNextLTArabic-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ntaqat';
  src: url('fonts/DIN/ntaqat.woff2') format('woff2'),
      url('fonts/DIN/ntaqat.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque medium';
  src: url('fonts/brandonGrotesque/BrandonGrotesque-Medium.woff2') format('woff2'),
      url('fonts/brandonGrotesque/BrandonGrotesque-Medium.woff') format('woff'),
      url('fonts/brandonGrotesque/BrandonGrotesque-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('fonts/brandonGrotesque/BrandonGrotesque-Regular.woff2') format('woff2'),
      url('fonts/brandonGrotesque/BrandonGrotesque-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Greek Book';
  src: url('fonts/gothamGreek/GothamGreek-Book.woff2') format('woff2'),
      url('fonts/gothamGreek/GothamGreek-Book.woff') format('woff'),
      url('fonts/gothamGreek/GothamGreek-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GE SS Text';
  src: url('fonts/GESS/GESSTextLight-Light.woff2') format('woff2'),
      url('fonts/GESS/GESSTextLight-Light.woff') format('woff'),
      url('fonts/GESS/GESSTextLight-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MB DECO';
  src: url('fonts/MB_DECO/MBDECO.woff2') format('woff2'),
      url('fonts/MB_DECO/MBDECO.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Professor';
  src: url('fonts/professor/Professor.woff2') format('woff2'),
      url('fonts/professor/Professor.woff') format('woff'),
      url('fonts/professor/Professor.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Recoleta';
  src: url('fonts/recoleta/Recoleta-SemiBold/Recoleta-SemiBold.woff2') format('woff2'),
      url('fonts/recoleta/Recoleta-SemiBold/Recoleta-SemiBold.woff') format('woff'),
      url('fonts/recoleta/Recoleta-SemiBold/Recoleta-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Recoleta';
  src: url('fonts/recoleta/Recoleta-Regular/Recoleta-Regular.woff2') format('woff2'),
      url('fonts/recoleta/Recoleta-Regular/Recoleta-Regular.woff') format('woff'),
      url('fonts/recoleta/Recoleta-Regular/Recoleta-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Recoleta Alt';
  src: url('fonts/recoleta/RecoletaAlt-Regular/RecoletaAlt-Regular.woff2') format('woff2'),
      url('fonts/recoleta/RecoletaAlt-Regular/RecoletaAlt-Regular.woff') format('woff'),
      url('fonts/recoleta/RecoletaAlt-Regular/RecoletaAlt-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'GE Dinar One';
  src: url('fonts/GEDinarOne/GEDinarOne.woff2') format('woff2'),
      url('fonts/GEDinarOne/GEDinarOne.woff') format('woff'),
      url('fonts/GEDinarOne/GEDinarOne.ttf') format('truetype'),
      url('fonts/GEDinarOne/GEDinarOne.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GE Dinar One-Light';
  src: url('fonts/GEDinarOne/GEDinarOne-Light.woff2') format('woff2'),
      url('fonts/GEDinarOne/GEDinarOne-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Akzidenz-Grotesk BQ';
  src: url('fonts/AkzidenzGroteskBQ-MdCndAlt/AkzidenzGroteskBQ-MdCndAlt.eot');
  src: url('fonts/AkzidenzGroteskBQ-MdCndAlt/AkzidenzGroteskBQ-MdCndAlt.eot?#iefix') format('embedded-opentype'),
      url('fonts/AkzidenzGroteskBQ-MdCndAlt/AkzidenzGroteskBQ-MdCndAlt.woff2') format('woff2'),
      url('fonts/AkzidenzGroteskBQ-MdCndAlt/AkzidenzGroteskBQ-MdCndAlt.woff') format('woff'),
      url('fonts/AkzidenzGroteskBQ-MdCndAlt/AkzidenzGroteskBQ-MdCndAlt.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Medium';
  src: url('fonts/Roboto/Roboto Medium/Roboto-Medium.woff2') format('woff2'),
      url('fonts/Roboto/Roboto Medium/Roboto-Medium.woff') format('woff'),
      url('fonts/Roboto/Roboto Medium/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Light';
  src: url('fonts/Roboto/Roboto Light/Roboto-Light.woff2') format('woff2'),
      url('fonts/Roboto/Roboto Light/Roboto-Light.woff') format('woff'),
      url('fonts/Roboto/Roboto Light/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tajawal Medium';
  src: url('fonts/Tajawal/Tajawal Medium/Tajawal-Medium.woff2') format('woff2'),
      url('fonts/Tajawal/Tajawal Medium/Tajawal-Medium.woff') format('woff'),
      url('fonts/Tajawal/Tajawal Medium/Tajawal-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tajawal Light';
  src: url('fonts/Tajawal/Tajawal Light/Tajawal-Light.woff2') format('woff2'),
      url('fonts/Tajawal/Tajawal Light/Tajawal-Light.woff') format('woff'),
      url('fonts/Tajawal/Tajawal Light/Tajawal-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Light';
  src: url('fonts/Avenir/Avenir Light/Avenir-Light.woff2') format('woff2'),
      url('fonts/Avenir/Avenir Light/Avenir-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('fonts/Avenir/AvenirLTStd-Book.woff2') format('woff2'),
      url('fonts/Avenir/AvenirLTStd-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tahoma Bold';
  src: url('fonts/Tahoma/Tahoma Bold/Tahoma-Bold.woff2') format('woff2'),
      url('fonts/Tahoma/Tahoma Bold/Tahoma-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Palatino';
  src: url('fonts/Palatino/Palatino.woff2') format('woff2'),
      url('fonts/Palatino/Palatino.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Palatino-Italic';
  src: url('fonts/Palatino/Palatino-Italic.woff2') format('woff2'),
      url('fonts/Palatino/Palatino-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}


/* @font-face {
  font-family: 'Professor';
  src: url('Professor_1.woff2') format('woff2'),
      url('Professor_1.woff') format('woff'),
      url('Professor_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */



html {
  scroll-behavior: smooth;
 
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Josefin Sans', sans-serif;
  /* font-family: 'Raleway', arial, sans-serif; */
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ul {
  padding-inline-start: 0px;
} */

div {
  box-sizing: border-box;

}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }


.map_style {
  width: 100%;
  height: 100%;
  border: 0;
}

/* -------------- Masonary Style --------------------- */

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  max-width: 1200px;
  margin-top: 80px;
  /* gutter size offset */
}

.my-masonry-grid img {

  width: 100%;
  border-radius: 12px;
}


.my-masonry-grid_column {
  /* gutter size */
  background-clip: padding-box;
}


/* -------------- Carousel Style  --------------------- */

.carousel-example {
  position: fixed !important;
  inset:0;
  z-index: 1;
  width: 100vw; 
}

.carousel-ind {
  font-weight: 400;
  font-size: 25px;
  padding: 30px;

}

.carousel-close {
  position: absolute;
  inset:0;
  cursor: pointer;
  left: 90%;
  padding: 30px 0px;

  z-index:100;
}

.carousel-child {
  position: absolute;
  inset:0;
  width: 100%;
  height: 100%;
  
}

.socials_page_carousel {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.socials_page_carousel p {
  width: 25%;
  text-align: center;
  
}

/* Brand Identity Image Containers and Image styles */
.grid-img-container {
  position: relative;
}

.grid-img-container img {
  cursor: pointer;
  width: 100%;
  border-radius: 12px;
  transition: all 300ms;
}


.food-menu-heading {
  margin-top: 30px;
  margin-bottom: 15px;
  padding-top: 60px;
  font-weight: bold;
  font-style: normal;
  font-size: 24px;
}
  

.food-menu-heading-ar {
  /* float: right; */
  text-align: right;
  margin-top: 30px;
  margin-bottom: 15px;
  padding-top: 60px;
  font-family: "DIN Next LT Arabic";
  font-weight: bold;
  font-style: normal;
  font-size: 24px;
}

/* ----- LSD nav bar ---------------- */

/*  lsd -intro, show , hide classes are for animating look later   */
.lsd-intro {
  position: fixed;
  inset:0;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  display: flex;
  gap: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #a8d9d6;
}

.lsd-intro-logo {
  position: relative;
  width: 50%;
  max-width: 500px;
}

.lsd-intro-show, .lsd-website-show {
  height: 100vh;
  transition: all 1000ms ease-in-out;
}

.lsd-intro-hide, .lsd-website-hide {
  height: 0;
  overflow: hidden;
  transition: all 1000ms ease-in-out;
}

.lsdNavBar ul, li, a {
  list-style: none; 
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: inherit;
}

.lsd-link {
  font-family: 'Recoleta';
  font-weight: normal;
 }

 .lsd-active-link {
  font-family: 'Recoleta';
  font-weight: 600;
 }

 

/* ------ shawarma el khebbez food menu -------- */
nav .foodNavBar {
  width:100%;

  /* to change menu to old settings delete z-index and position */
  z-index: 1;
  position:fixed;
  
  max-width: 1200px;
  height: 50px;
  /* position: fixed !important; */
  padding: 0px 15px;
  padding-bottom: 20px !important;
  background-color: rgb(241, 239, 217);
  border: 2px solid transparent;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5);
}

/* ------- white coffee spot menu ------ */
nav .wcsNavBar {
  position: fixed;
  width:92vw;
  margin-left: 16px;
  background-color: white;
}

nav .wcsNavBar ul {
  /* copy from food nav bar ul */
  height: 50px;
  display: flex;
  padding:0px 30px;
  justify-content: space-between;
  
}

nav .wcsNavBar ul li {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  /* border: 2px solid black; */
}

nav .wcsNavBar ul li a {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: black;
  cursor: pointer;
  font-family: 'Ambition & Ink';
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
}

nav .wcsNavBar ul li img {
  visibility: hidden;
}

/* white coffee spot menu arabic */
nav .wcsNavBarAr {
  position: fixed;
  width:95vw;
  margin-left: 30px;
  background-color: white;
}

nav .wcsNavBarAr ul {
  /* copy from food nav bar ul */
  height: 50px;
  display: flex;
  padding:0px 30px;
  justify-content: space-between;
  
}

nav .wcsNavBarAr ul li {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  /* border: 2px solid black; */
}

nav .wcsNavBarAr ul li a {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: black;
  cursor: pointer;
  font-family: 'DIN Next LT Arabic';
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
}

nav .wcsNavBarAr ul li img {
  visibility: hidden;
}

nav .wcsNavBarAr ul li.active img {
  visibility: visible;
  margin-top: 5px;
}

/* end of white coffee spot arabic */

nav .foodNavBar ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  /* white-space: nowrap;
  padding: 0; */
}

nav .foodNavBar ul li {
  display: inline-block;
  border-radius: 5px;
}

/* this will be different for each menu*/
nav .foodNavBar ul li a {
  position: relative;
  text-decoration: none;
  display: inline-block;
  text-decoration: none;
  color: black;
  cursor: pointer;
  
  font-size: 22px;
  font-weight: 800;
}


nav .foodNavBar ul li a::after {
  position: absolute;
  content: "";
  opacity: 0;
  height: 2px;
  border: 2px solid transparent;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  left: -3px;
  bottom: -8px !important;
  background-color: rgb(258, 61, 48);
}

nav .foodNavBar ul li a::after {
  transition: 300ms;
  width: 0%;
}

nav .foodNavBar ul li.active a {
  color: rgb(258, 61, 48);
  font-weight: 800;
  font-size: 24px;
  transition: 0.3s
}

nav .wcsNavBar ul li.active img {
  visibility: visible;
  margin-top: -4px;
}

nav .foodNavBar ul li.active a::after {
  position: absolute;
  content: "";
  opacity:100;
  width: 100%;
  height: 2px;
  border: 2px solid transparent;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  left: -3px;
  bottom: -8px;
  background-color: rgb(258, 61, 48);
}

/* --------- shaqab food menu ---------- */
.shaqab-nav {
  padding: 0px 28px !important;
}

.shaqab-font {
  font-size: 16px !important;
}

/* --------- Le Train Bleu Food Menu ---------- */
.category-title {
  color: #dbbc74;
  font-family: "NeonoirW01" !important;
  font-size:14px !important;
  font-weight: bold !important;
  font-style: normal !important;
  font-display: swap !important;
}

.custom-accordion-ltb {
  background-color: #011e41 !important;
}

.custom-accordion-lt::before {
  content: "";
  background-color: red !important;
  /* add more styles as needed */
}

.dish-title {
    
    color: #dbbc74 !important;
    padding-top:0px;
    padding-bottom: 12px;
    font-family: "Palatino LT";
    font-size: 14px;
}

.dish-heading {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 12px;
  color: #dbbc74;
  font-family: "NeonoirW01";
  font-size: 26px;
  height: 17%;
  width:66%;
  margin-left: 17%;
  //border-bottom: 1px solid rgba(219, 188, 116,0.6);
  z-index: 1;
}

.dish-image-container img {
  position: absolute;
  inset: 0;
  width: 100%;
}

.dish-close-icon {
  position: absolute;
  inset:0;
  margin-left:77%;
  margin-top: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dbbc74;
 
  width: 50px;
  height: 50px;
  font-size: 16px;
  cursor: pointer;
}

.afternoon_tea_menus {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 5% 10%;
}

.ltb-accordion-test {
  background-color: #011e41 !important;
}
.nested_accordia_title {
  display: flex;
  justify-content: space-around;
  color: #dbbc74;
  font-size: 12px;
}


.ltb-menu-filler {
  position: relative;
  background-color: #011e41;
  height:60px;
}

/* --------- Le Train Bleu Food Menu ---------- */

/* .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
 
  box-shadow: red !important;
} *

/

/* --------- Ergon Menu ---------- */
.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  display: none !important;
}


.css-1n3tkec::before {
  display: none !important;
}
.css-1udso76-MuiPaper-root-MuiAccordion-root:before {
  display: none !important;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  color: black !important;
  transform: rotate(-90deg) !important;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(0deg) !important;
}

/* this is the grid of each categories margin */
/* .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded
{
  margin: 0px !important;
  box-shadow: none !important;
 
} */


.grid-img-container img:hover {
  /* box-shadow: 5px 5px 5px grey; */
  transform: scale(1.05);
}

.MuiGrid-container {
  margin-top: 41px;
}
.test {
  max-height: 85%;
  max-width: 90%;
}

.test-cover {
  width: 100px;
  height: 100px;
  background-color: red;
}
/* carousels images styling */
/* portrait styling */
#envPic1, #envPic2, #envPic3, #envPic6, #photographyPic1, #photographyPic3, #photographyPic4,  #photographyPic9,  #photographyPic11,  #photographyPic18   {
  max-height: 85%;
  max-width: 90%;

}
/* landscape styling */
#envPic4, #envPic5, #envPic7, #envPic8, #photographyPic2, #photographyPic5, #photographyPic6, #photographyPic7, #photographyPic8,  #photographyPic10,  #photographyPic12,  #photographyPic13,  #photographyPic14,  #photographyPic15,  #photographyPic16,  #photographyPic17, #photographyPic19, #photographyPic20
{
  max-height: 85%;
  max-width: 85%;
}



.button-container {
  margin-bottom: 0.5rem;
}

.fade-enter  {
  opacity: 0;
  transform: translateY(100%);
}
.fade-enter-active  {
  opacity: 1;
  transform: translateY(0%);
}
.fade-exit  {
  opacity: 1;
  transform: translateY(0%);
}
.fade-exit-active {
  opacity: 0;
  transform: translateY(-100%);
}
.fade-enter-active ,
.fade-exit-active  {
  transition: opacity 500ms, transform 4000ms;
}

/* All element transitions are added here */


.animate__animated.animate__slideInLeft{
  --animate-duration: 300ms;
}

.animate__animated.animate__slideOutLeft {
  --animate-duration: 300ms;
}

.animate__animated.animate__slideInUp {
  --animate-duration: 600ms;
}

/* media queries */

/* white coffee spot menu responsivness */
@media(min-width: 700px) {
  nav .wcsNavBar ul  {
    justify-content: center;
    gap: 50px;
  }

  nav .wcsNavBarAr ul {
    justify-content: center;
    gap: 50px;
  }

  nav .wcsNavBar ul li a {
    font-size: 30px;
  }

  nav .wcsNavBarAr ul li a {
    font-size: 50px;
  }
}

@media(max-width: 700px ) {
  .lsdNavBar {
    display: none;
  }
}
@media (max-width: 800px) {
  .carousel-close {
    left: 70%;
    padding: 30px 15px;
  }

  .css-1abc02a, .css-hn784z {
    visibility: hidden;
  }

  .socials_page_carousel p {
      width: 70%;
    }
  
  nav .foodNavBar ul li a {
      font-size: 9px;
    }
  
  nav .foodNavBar ul li a::after {
      bottom: -17px !important;
    }

    nav .foodNavBar ul li.active a {
      font-size: 10px;
    }
}

@media only screen and (min-width: 500px) {
  .dish-close-icon {
    width: 100px;
    height: 100px;
    top: -20px;
  }
}

@media only screen and (min-width: 1024px) {
  .ltb-menu-filler {
    position: relative;
    background-color: #011e41;
    height:610px;
  }

  .dish-close-icon {
    width: 200px;
    height: 200px;
    top: -80px;

  }

  .svg-inline--fa {
    font-size: 50px;
    padding-right: 30px;
  }
}


/* @media only screen and (min-width: 1500px) {
  .dish-close-icon {
    width: 300px;
    height: 300px;
    top: -3%;
  }
} */

